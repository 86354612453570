import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const EditUserRoleApi = async (reqData : any) => {

  const res = await axios.post('api/admin/userEditRole', reqData, HEADER());
  
  return res.data
};

export { EditUserRoleApi };
