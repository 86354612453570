import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import React, { useState } from 'react';

export default function MobileHeader() {

    // Define the state with a type that can be null or HTMLButtonElement
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const Navigate = useNavigate();

    // Adjust the event type and use a type assertion
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAboutUsClick = () => {
        setAnchorEl(null);

        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("aboutUsSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handlePricingClick = () => {
        setAnchorEl(null);

        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("pricingSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleCreateAccountClick = () => {
        Navigate("/auth/signup")
    };

    const handleLoginClick = () => {
        Navigate("/auth/login")
    };

    return (
        <>
            <div className="flex h-14 w-[90%] absolute bg-white justify-between z-50 ml-[5%] mt-[20px] top-0 items-center">
                <IconButton size="large" aria-label="delete" onClick={handleClick}>
                    <MenuIcon sx={{ width: "30px", height: "30px", color: "#c7cad9" }} />
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>Home</MenuItem>
                    <MenuItem onClick={handleAboutUsClick}>About us</MenuItem>
                    <MenuItem onClick={handlePricingClick}>Pricing</MenuItem>
                </Menu>
                <div className="flex w-auto h-[80%] mr-[10px]">
                    <div onClick={handleCreateAccountClick} className="w-auto h-[100%] p-[5px] pt-[7px] bg-[#005AFF] justify-center items-center text-[#fff] hover: cursor-pointer"><a>Create account</a></div>
                    <div onClick={handleLoginClick} className="ml-[1px] w-auto h-[100%] p-[5px] pt-[7px] bg-[#4B58A6] justify-center items-center text-[#fff] hover: cursor-pointer"><a>Login</a></div>
                </div>
            </div>
        </>
    );
}

