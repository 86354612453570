import { Fragment, useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-hot-toast";
import { THEME } from "../../../constant";
import { WithdrawApi } from "../../../api/transaction/withdraw";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';

export default function WithdrawModal(props: any) {
    let [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [amount, setAmount] = useState<number>(0);

    function closeModal() {
        setIsOpen(false);
    }

    const handleAmountChange = (event: any) => {
        setAmount(event.target.value);
    };

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleComplete = async () => {
        if (amount <= 0) {
            toast.error("Amount is not available");
        } else {
            setLoading(true);

            const reqData = {
                withdrawAmount: amount
            };
            const res = await WithdrawApi(reqData);

            if (res.status != 200) {
                toast.error(res.message);
                setLoading(false);
            } else {
                toast.success("Withdrawal successfully processed");
                setLoading(false);
                setIsOpen(false);
            }
        }
    };

    return (
        <>
            <Button
                startIcon={<CurrencyExchangeOutlinedIcon />}
                sx={{ ":hover": { opacity: 0.8 }, width: "100%", height:'100%' }}
                variant="contained"
                onClick={handleClick}
            >
                Withdraw
            </Button>
            <Dialog
                open={isOpen}
                onClose={closeModal}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        width: "50%",
                        minWidth: "400px",
                        backgroundColor: THEME.WHITE_COLOR,
                    },
                }}
            >
                <DialogTitle>Withdraw your profit</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        label="amount ($)"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={handleAmountChange}
                    />
                    <div className="h-2" />

                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ background: "#2e3539" }}
                        variant="contained"
                        onClick={closeModal}
                    >
                        Back
                    </Button>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<SendIcon />}
                        variant="contained"
                        sx={{ background: "#2e3539" }}
                        onClick={handleComplete}
                    >
                        Complete Withdraw
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
