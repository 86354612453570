import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from "react-router-dom";
import { EventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { LoginApi } from "../../api";
import { BACKEND_URL } from "../../constant";

export default function LoginForm() {
    const Navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePassChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleClick = async () => {

        if (email == "" || password == "") {
            toast.error("Fill all the blank.");
        } else {
            const emailValidRegex =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!email.match(emailValidRegex)) {
                toast.error("Invalid email.");
            } else {
                // api for auth here.
                const req = {
                    email,
                    password,
                };

                const resAuthLogin = await LoginApi(req);

                if (resAuthLogin.data.status == 200) {
                    localStorage.setItem("t", resAuthLogin.data?.token);
                    toast.success(resAuthLogin.data?.message);
                    Navigate("/dashboard");
                } else toast.error(resAuthLogin.data?.message);
            }
        }
    };

    return (
        <>
            <div className="w-[50%] max-w-[600px] min-w-[400px] h-auto rounded-[20px] bg-[white]">
                <div onClick={() => Navigate("/dashboard")} className="flex w-[100%] h-auto ml-[20px] mt-[40px] mb-[20px] hover:cursor-pointer">
                    <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01"></div>
                    {/* <a className="text-[#4B58A6] text-[20px] font-bold ml-[10px]">tollbooth</a> */}
                </div>
                <div className="w-[60%] h-auto ml-[20%] mb-[50px]">
                    <TextField onChange={handleEmailChange} id="login-email" label="Enter your email" type="email" variant="outlined" style={{ width: '100%', marginTop: '10px', borderRadius: '20px' }} />
                    <TextField onChange={handlePassChange} id="login-password" type="password" label="Enter your password" variant="outlined" style={{ width: '100%', marginTop: '20px', borderRadius: '20px' }} />
                    <Button onClick={handleClick} variant="contained" style={{ width: '100%', marginTop: '20px', backgroundColor: '#005aff' }}>Login</Button>
                    <div className="bg-content-divider bg-no-repeat bg-contain w-[100%] h-5 mt-[20px]"></div>
                    <Button variant="outlined" style={{ width: '100%', height: '50px', marginTop: '20px', borderColor: '#666', color: '#666', alignItems: 'center', display: 'flex' }} startIcon={<GoogleIcon style={{ fontSize: '40px', color: '#005aff' }} />}>Sign in with Google</Button>
                    <Button variant="outlined" style={{ width: '100%', height: '50px', marginTop: '20px', borderColor: '#666', color: '#666', alignItems: 'center', display: 'flex' }} startIcon={<AppleIcon style={{ fontSize: '40px' }} />}>Sign in with Google</Button>
                    <div className="flex justify-center text-[#936366]">
                        <a href="/auth/signup">Or don't you have any account?</a>
                    </div>
                </div>
            </div>
        </>
    );
}
