import AdminHeader from "../../components/admin/header";
import AdminNavbar from "../../components/admin/navbar";
import UserOverviewBoard from "../../components/admin/user_overview";
import UserTable from "../../components/admin/user_table";
import { useMediaQuery } from "react-responsive";

function AdminDashboard() {
  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });
  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <AdminHeader />
        <div
          className={
            isLaptopOrMobile
              ? "flex justify-between w-[95%]"
              : "flex justify-between w-[100%]"
          }
        >
          <AdminNavbar />
          <span className="w-[3%]" />
          <div className="w-[100%] ">
            <div className="mt-[100px] overflow-auto">
              <UserOverviewBoard />
              <div className="w-[100%] mt-[30px]">
                <UserTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
