export default function DashboardAdViewContent() {

    return (
        <>
            <div className="w-[100%] h-auto relative bg-[#4b58a6]">
                <div className=" flex justify-center">
                    <a className="mt-10 text-[15px] text-[white]" >Why Choose Email Monetizer</a>
                </div>
                <div className="mt-10 mr-auto ml-auto w-[40px] h-[40px] bg-featured_icon1 bg-contain "></div>
                <div className="mt-8 flex justify-center">
                    <h1 className="text-[20px] text-[white] font-normal" >Auto-Reply Functionality</h1>
                </div>
                <div className="mt-5 flex ml-auto mr-auto justify-center text-center max-w-[500px]">
                    <h1 className="text-[15px] text-[white] font-normal" >The extension will automatically reply to promotional or spam emails, informing the sender about the payment option to prioritize their email.</h1>
                </div>
                <div className="mt-10 mr-auto ml-auto w-[40px] h-[40px] bg-featured_icon2 bg-contain "></div>
                <div className="mt-8 flex justify-center">
                    <h1 className="text-[20px] text-[white] font-normal" >Payment Processing</h1>
                </div>
                <div className="mt-5 flex ml-auto mr-auto justify-center text-center max-w-[500px]">
                    <h1 className="text-[15px] text-[white] font-normal" >Integration with Stripe to handle payments from senders who wish to have their emails prioritized.</h1>
                </div>

                <div className="mt-10 mr-auto ml-auto w-[40px] h-[40px] bg-featured_icon3 bg-contain "></div>
                <div className="mt-8 flex justify-center">
                    <h1 className="text-[20px] text-[white] font-normal" >Recipient Monetization</h1>
                </div>
                <div className="mt-5 flex ml-auto mr-auto justify-center text-center max-w-[500px]">
                    <h1 className="mb-10 text-[15px] text-[white] font-normal" >The recipient of the email will receive the payment made by the sender, minus a service fee retained by the extension platform.</h1>
                </div>
                
            </div>
        </>
    );
}
