import { useMediaQuery } from "react-responsive";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import React, { useEffect, useState } from "react";
import { WithdrawModal } from "../../dialog";
import { GetUsersOverViewApi } from "../../../api/admin/getUsersOverview";

export default function UserOverviewBoard() {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSubscribedUsers, setTotaSubscribedlUsers] = useState(0);
  const [totalAdminCount, setTotalAdminCount] = useState(0);
  const [name, setName] = useState("");

  const getUsersOverview = async () => {
    const data = await GetUsersOverViewApi();

    setName(data.data.name);
    setTotalUsers(data.data.totalUser);
    setTotaSubscribedlUsers(data.data.subscribedUserCount)
    setTotalAdminCount(data.data.totalAdminCount)
  };

  const handleWithdrawClick = async () => {};

  useEffect(() => {
    getUsersOverview();
  }, []);

  return (
    <>
      <div className="w-[100%] h-auto relative ">
        <div className="w-[100%] h-auto bg-white pb-8 rounded-[5px]">
          <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold text-[#121212]">
            <a>👋 Hi {name} !</a>
          </div>

          <div className="flex justify-between">
            <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
              <a>Total users : </a>
              <a className="font-normal text-[25px]">{totalUsers}</a>
            </div>

            <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
              <a>Subscribed Users : </a>
              <a className="font-normal text-[25px]">{totalSubscribedUsers}</a>
            </div>

            <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
              <a>Admin : </a>
              <a className="font-normal text-[25px]">{totalAdminCount}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
