import { useMediaQuery } from "react-responsive";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { TokenValidationApi } from "../../../api/auth/TokenValidation";
import { BACKEND_URL } from "../../../constant";
import { toast } from "react-hot-toast";

export default function AdminHeader() {
  // const [name, setName] = useState("");
  // const [avatar, setAvatar] = useState("");

  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });

  const Navigate = useNavigate();

  // const handleAboutUsClick = () => {
  //     // Navigate to AboutUs component using smooth scrolling
  //     const aboutUsElement = document.getElementById("aboutUsSection");
  //     if (aboutUsElement) {
  //         aboutUsElement.scrollIntoView({ behavior: "smooth" });
  //     }
  // };

  // const handlePricingClick = () => {
  //     // Navigate to AboutUs component using smooth scrolling
  //     const aboutUsElement = document.getElementById("pricingSection");
  //     if (aboutUsElement) {
  //         aboutUsElement.scrollIntoView({ behavior: "smooth" });
  //     }
  // };

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Adjust the event type and use a type assertion
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget as HTMLElement);
  // };

  // const handleClose = () => {
  //     setAnchorEl(null);
  // };

  const tokenValidation = async () => {
    const admin = await TokenValidationApi();
    if (admin.status != 200 || !admin) {
      Navigate("/auth/login");
    }

    if (admin.data.role != "admin") {
      toast.error("You have no permission.");
      Navigate("/");
    }
  };

  useEffect(() => {
    tokenValidation();
  }, []);

  return (
    <>  
      <div className={isLaptopOrMobile ? "flex h-14 w-[90%] absolute bg-white justify-between z-50 ml-[5%] mt-[20px] top-0 items-center" : "flex h-14 w-[100%] absolute bg-white justify-between z-50 mt-[20px] top-0 items-center"} >
        <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01"></div>
      </div>
    </>
  );
}
