import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const GetUsersOverViewApi = async () => {

  const res = await axios.post('api/admin/usersOverView', {}, HEADER());
  
  return res.data
};

export { GetUsersOverViewApi };
