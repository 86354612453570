export default function FooterMobile() {

    return (
        <>
            <div className="w-[100%] h-auto bg-[white]">
                <div className="w-[100%] h-[50px] bg-[white]"></div>
                <div className="w-[80%] ml-auto mr-auto flex justify-between">
                    {/* <div>
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">Home</a>
                        </div>

                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">Pricing</a>
                        </div>
                    </div> */}

                    <div>
                        <a className="text-[#005aff] text-[20px] font-bold">Legal</a><br />
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">Privacy policy</a>
                        </div>

                    </div>

                    <div>
                        <a className="text-[#005aff] text-[20px] font-bold">Contact us</a><br />
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">info@tollbooth.app</a>
                        </div>


                    </div>
                </div>
                <div className="w-[100%] h-[30px] bg-[white] text-center mt-[50px] text-[#699BF7]"><a>Copyright © 2024 First Providence Group, LLC.</a></div>
                <div className="w-[100%] h-[30px] bg-[white] text-center text-[#699BF7]"><a>All rights Reserved</a></div>
            </div>
        </>
    );
}
