import { Fragment, useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-hot-toast";
import { THEME } from "../../../constant";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { EditUserRoleApi } from "../../../api/admin/editUserRole";

export default function EditUser(data: any) {
  let [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(data.data.email);
  const [role, setRole] = useState(data.data.role);
  const [open, setOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    setIsOpen(true);
    setRole(data.data.role);
  };

  const handleRoleChange = (event: SelectChangeEvent<typeof role>) => {
    setRole(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (role == data.data.role) {
      toast.error("No change");
    } else {
      setLoading(true);

      const reqData = {
        email,
        role,
      };
      const res = await EditUserRoleApi(reqData);

      if (res.status == 200) {
        toast.success("success");

        setLoading(false);
        setIsOpen(false);
        window.location.reload();
      } else {
        toast.error(res.message);
      }
    }
  };

  return (
    <>
      <Tooltip arrow placement="left" title="Edit">
        <IconButton
          aria-label="delete"
          sx={{ color: "gray" }}
          onClick={handleClick}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "50%",
            minWidth: "400px",
            backgroundColor: THEME.WHITE_COLOR,
          },
        }}
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <div>name : {data.data.name}</div>
          <div className="h-2" />
          <div>email : {data.data.email}</div>
          <div className="h-8" />
          <FormControl fullWidth>
            <InputLabel id="demo-controlled-open-select-label">Role</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={role}
              label="Role"
              fullWidth
              onChange={handleRoleChange}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>

          <div className="h-8" />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ background: "#2e3539" }}
            variant="contained"
            onClick={closeModal}
          >
            Back
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="contained"
            sx={{ background: "#2e3539" }}
            onClick={handleSave}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
