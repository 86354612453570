import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { TokenValidationApi } from "../../../api/auth/TokenValidation";
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useLocation } from 'react-router-dom';

export default function MainNavbar() {

    const location = useLocation();

    const path = location.pathname;

    const isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
    });

    const Navigate = useNavigate();

    const handleProrileSettings = async () => {
        Navigate('/profile-setting')
    }

    const handlePayments = async () => {
        Navigate('/payments')
    }

    const handleDashboardClick = async () => {
        Navigate('/dashboard')
    }

    return (
        <>
            {isLaptopOrMobile ? (
                <>
                    <div className="w-[290px] h-[calc(screen-100px)] mt-[100px] bg-[#4B58A6] ml-[5%] ">
                        <div className="ml-5 bg-no-repeat bg-center bg-cover h-20 w-[250px] bg-WhiteLogo"></div>
                        
                        <div  onClick={handleDashboardClick} style={path === "/dashboard" ? { backgroundColor: 'white', color: '#005AFF' } : { backgroundColor: 'none' }} className="w-[90%] ml-[5%] h-[50px] mt-[50px] rounded-[5px] items-center  text-white flex hover:cursor-pointer hover:bg-white hover:text-[#005AFF]">
                            <div className="ml-5 bg-no-repeat bg-center bg-cover h-6 w-6  ">
                                <BarChartOutlinedIcon />
                            </div>
                            <a className="ml-5 mt-2 text-[16px] font-[600]">Dashboard</a>
                        </div>

                        <div style={path === "/monetized-email" ? { backgroundColor: 'white', color: '#005AFF' } : { backgroundColor: 'none' }} className="w-[90%] ml-[5%] h-[50px] mt-[20px] rounded-[5px] items-center text-white flex hover:cursor-pointer hover:bg-white hover:text-[#005AFF]">
                            <div className="ml-5 bg-no-repeat bg-center bg-cover h-6 w-6 ">
                                <MarkEmailUnreadOutlinedIcon />
                            </div>
                            <div className="ml-5 mt-1 text-[16px] font-[600]">Monetized emails</div>
                        </div>

                        <div onClick={handlePayments} style={path === "/payments" ? { backgroundColor: 'white', color: '#005AFF' } : { backgroundColor: 'none' }} className="w-[90%] ml-[5%] h-[50px] mt-[20px] rounded-[5px] items-center text-white flex hover:cursor-pointer hover:bg-white hover:text-[#005AFF]">
                            <div className="ml-5 bg-no-repeat bg-center bg-cover h-6 w-6  ">
                                <PaymentsOutlinedIcon />
                            </div>
                            <div  className="ml-5  mt-1 text-[16px] font-[600]">Payments</div>
                        </div>

                        <div onClick={handleProrileSettings} style={path === "/profile-setting" ? { backgroundColor: 'white', color: '#005AFF' } : { backgroundColor: 'none' }} className="w-[90%] ml-[5%] h-[50px] mt-[200px] rounded-[5px] items-center text-white flex hover:cursor-pointer hover:bg-white hover:text-[#005AFF]">
                            <div className="ml-5 bg-no-repeat bg-center bg-cover h-6 w-6  ">
                                <AccountCircleOutlinedIcon />
                            </div>
                            <div className="ml-5  mt-1 text-[16px] font-[600]">Profile Settins</div>
                        </div>

                        <div className="w-[90%] ml-[5%] h-[50px] mt-[10px] rounded-[5px] items-center text-white flex hover:cursor-pointer hover:bg-white hover:text-[#005AFF]">
                            <div className="ml-5 bg-no-repeat bg-center bg-cover h-6 w-6  ">
                                <LogoutOutlinedIcon />
                            </div>
                            <div className="ml-5  mt-1 text-[16px] font-[600]">Logout</div>
                        </div>

                    </div>
                </>
            ) : (
                <>
                </>
            )}

        </>
    );
}
