import { useMediaQuery } from "react-responsive";
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from 'react';
import { TokenValidationApi } from "../../../api/auth/TokenValidation";
import { BACKEND_URL } from "../../../constant";

export default function MainHeader() {

    const [name, setName] = useState("");
    const [avatar, setAvatar] = useState("");
    const location = useLocation();

    const isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
    });

    const Navigate = useNavigate();

    const handleAboutUsClick = () => {
        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("aboutUsSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handlePricingClick = () => {
        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("pricingSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // Adjust the event type and use a type assertion
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHome = () => {
        setAnchorEl(null);
        Navigate('/')
    }

    const handleDashboard = () => {
        Navigate('/dashboard')
    }

    const tokenValidation = async () => {
        const user = await TokenValidationApi();
        if (user.status != 200 || !user) {
            Navigate('/auth/login')
        }
        setName(user.data.name);
        setAvatar(user.data.avatar);

        if(user.data.paymentStatus != 'active') {
            const pathname = location.pathname;

            if(!pathname.includes("/stripe/cancel") && !pathname.includes("/stripe/success")) {
                Navigate('/subscribe')
            }
        }

    }

    useEffect(() => {
        tokenValidation();
    }, [])

    return (
        <>
            {isLaptopOrMobile ? (
                <>
                    <div className="flex h-14 w-[90%] absolute bg-white justify-between z-50 ml-[5%] mt-[20px] top-0 items-center">
                        <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01 hover:cursor-pointer" onClick={handleDashboard}></div>
                        <div className="flex w-auto h-[100%] items-center font-sans text-[20px] text-[#699BF7]">
                            <a href={BACKEND_URL} className="mr-[30px]">Home</a>
                            {/* <a href={BACKEND_URL} className="mr-[30px]" onClick={handleAboutUsClick}>About us</a> */}
                            <a href={BACKEND_URL} onClick={handlePricingClick}>Pricing</a>
                        </div>
                        <div className="flex w-auto h-[80%] mr-[10px]">
                            <Avatar alt={name} src={avatar} />
                            <div className="ml-[1px] w-auto h-[100%] p-[5px] pt-[7px] font-bold justify-center items-center text-[#699BF7] hover: cursor-pointer"><a>{name}</a></div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex h-14 w-[100%] absolute bg-white justify-between z-50 mt-[20px] top-0 items-center">
                        <IconButton size="large" aria-label="delete" onClick={handleClick}>
                            <MenuIcon sx={{ width: "30px", height: "30px", color: "#c7cad9" }} />
                        </IconButton>

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleHome}>Home</MenuItem>
                            <MenuItem onClick={handleAboutUsClick}>About us</MenuItem>
                            <MenuItem onClick={handlePricingClick}>Pricing</MenuItem>
                        </Menu>
                        <div className="flex w-auto h-[80%] mr-[10px]">
                            <Avatar alt={name} src={avatar} />
                            <div className="ml-[1px] w-auto h-[100%] p-[5px] pt-[7px] font-bold justify-center items-center text-[#699BF7] hover: cursor-pointer"><a>{name}</a></div>
                        </div>
                    </div>
                </>
            )}

        </>
    );
}
