import axios from "../../utils/axios";

const LoginApi = async (data: { email: string; password: string }) => {

  const res = await axios.post('api/auth/login', data);
  
  return res
};

export { LoginApi };
