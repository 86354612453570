import { useMediaQuery } from "react-responsive";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import React, { useEffect, useState } from "react";
import { GetUsersOverViewApi } from "../../../api/admin/getUsersOverview";
import { GetTransactionOverViewApi } from "../../../api/admin/getTransactionOverView";

export default function TransactionOverviewBoard() {
  const [totalTransactions, setTotalTransactions] = useState(0);
  // const [totalWithdraw, setTotalWithdraw] = useState(0);
  // const [currentBalance, setCurrentBalance] = useState(0);
  const [name, setName] = useState("");

  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });

  const getTransactionsOverview = async () => {
    const data = await GetTransactionOverViewApi();

    setName(data.data.name);
    setTotalTransactions(data.data.totalTransaction);
  };

  const handleWithdrawClick = async () => {};

  useEffect(() => {
    getTransactionsOverview();
  }, []);

  return (
    <>
      <div className="w-[100%] h-auto relative ">
        <div className="w-[100%] h-auto bg-white pb-8 rounded-[5px]">
          <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold text-[#121212]">
            <a>👋 Hi {name} !</a>
          </div>

          <div className="flex justify-between">
            <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
              <a>Total Transactions : </a>
              <a className="font-normal text-[25px]">{totalTransactions}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
