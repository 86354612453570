import AdminHeader from "../../components/admin/header";
import AdminNavbar from "../../components/admin/navbar";
import TransactionOverviewBoard from "../../components/admin/transaction_overview";
import TransactionTable from "../../components/admin/transaction_table";
import { useMediaQuery } from "react-responsive";

function AdminTransactions() {
    const isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
      });
  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <AdminHeader />
        <div className={isLaptopOrMobile ? "flex justify-between w-[95%]" : "flex justify-between w-[100%]"}>
          <AdminNavbar />
          <span className="w-[3%]" />

          <div className="w-[100%] ">
            <div className="mt-[100px] overflow-auto">
              <TransactionOverviewBoard />
              <div className="w-[100%] mt-[30px]">
                <TransactionTable />
              </div>
              
            </div>


          </div>
        </div>

      </div>
    </>
  );
}

export default AdminTransactions;
