import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { EventHandler, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { RegisterApi } from "../../../api/auth/Register";
import { CreateSessionApi } from "../../../api/stripe/createSession";
import { loadStripe } from "@stripe/stripe-js";
import { StripePubKey } from "../../../constant";
import { encodeAES } from "../../../@core/utils/encodeAES";

const stripePromise = loadStripe(StripePubKey);

export default function SignupMobileForm() {
  const Navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePassChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleClick = async () => {
    if (email == "" || password == "" || name == "") {
      toast.error("Fill all the blank.");
    } else {
      const emailValidRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!email.match(emailValidRegex)) {
        toast.error("Invalid email.");
      } else {
        try {
          const res = await CreateSessionApi(email);

          const { sessionId } = res;

          if (res.status == 200) {

            const encryptedSessionId = encodeAES(sessionId);
            localStorage.setItem("s", encryptedSessionId);
            const encryptedEmail = encodeAES(email);
            localStorage.setItem("e", encryptedEmail);
            const encryptedPassword = encodeAES(password);
            localStorage.setItem("p", encryptedPassword);
            const encryptedName = encodeAES(name);
            localStorage.setItem("n", encryptedName);

            const stripe = await stripePromise;

            if (!stripe) {
              toast.error("Failed to load Stripe.");
              return;
            }

            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
              toast.error("Error redirecting to Stripe.");
            }
          } else {
            toast.error(res.message);
          }
        } catch (error) {
          console.log(error);
          toast.error("Error connecting to Stripe.");
        }
      }
    }
  };

  return (
    <>
      <div className="w-[100%] h-screen bg-[white]">
        <div
          onClick={() => Navigate("/dashboard")}
          className="w-[80%] h-auto ml-[20px] mt-[40px] mb-[20px] hover:cursor-pointer"
        >
          <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01"></div>
        </div>
        <div>
          <div className="w-[70%] h-auto ml-[15%]">
            <div className="w-[100%] h-auto text-[#344054]">
              <a>Name *</a>
              <TextField
                id="login-name"
                type="text"
                onChange={handleNameChange}
                label="Enter your name"
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: "20px",
                }}
              />
              <a>Email *</a>
              <TextField
                id="login-email"
                type="email"
                onChange={handleEmailChange}
                label="Enter your email"
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: "20px",
                }}
              />
              <a>Password *</a>
              <TextField
                id="login-password"
                type="password"
                onChange={handlePassChange}
                label="Create a password"
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  borderRadius: "20px",
                }}
              />
              <a>Must be at least 8 characters *</a>
              <Button
                variant="contained"
                onClick={handleClick}
                style={{
                  width: "100%",
                  marginTop: "20px",
                  backgroundColor: "#005aff",
                }}
              >
                Get started
              </Button>
              <div className="bg-content-divider bg-no-repeat bg-contain w-[100%] h-5 mt-[20px]"></div>
              <Button
                variant="outlined"
                style={{
                  width: "100%",
                  height: "50px",
                  marginTop: "20px",
                  borderColor: "#666",
                  alignItems: "center",
                  display: "flex",
                }}
                startIcon={
                  <GoogleIcon style={{ fontSize: "40px", color: "#005aff" }} />
                }
              >
                Signup with Google
              </Button>
              {/* <Button variant="outlined" style={{ width: '100%', height: '50px', marginTop: '20px', borderColor: '#666', alignItems: 'center', display: 'flex' }} startIcon={<AppleIcon style={{ fontSize: '40px' }} />}>Sign up with Google</Button> */}
              <div className="flex justify-center text-[#936366]">
                <a href="/auth/login">Or you already have an account?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
