import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from "@mui/material/Button";
import { THEME, DASHBOARD, SA_MANAGEMENT } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import MobileHeader from "../mobile/header";

export default function Header() {
    const isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
    });

    const Navigate = useNavigate();

    const currentPage = useSelector((state: any) => state.currentPageReducer.currentPage);

    let backgroundDashboard = ''
    let backgroundSuperAdmin = ''

    if (currentPage == 'dashboard') {
        backgroundDashboard = THEME.PRIMARY_COLOR;
    } else {
        backgroundDashboard = THEME.HOME_BOARD_COLOR;
    }

    if (currentPage == 'superadmin') {
        backgroundSuperAdmin = THEME.PRIMARY_COLOR;
    } else {
        backgroundSuperAdmin = THEME.HOME_BOARD_COLOR;
    }

    const handleAboutUsClick = () => {
        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("aboutUsSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handlePricingClick = () => {
        // Navigate to AboutUs component using smooth scrolling
        const aboutUsElement = document.getElementById("pricingSection");
        if (aboutUsElement) {
            aboutUsElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleCreateAccountClick = () => {
        Navigate("/auth/signup")
    };

    const handleLoginClick = () => {
        Navigate("/auth/login")
    };


    return (
        <>
            <>
                <div className="flex h-14 w-[90%] absolute bg-white justify-between z-50 ml-[5%] mt-[20px] top-0 items-center">
                    <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01"></div>
                    <div className="flex w-auto h-[100%] items-center font-sans text-[20px] text-[#699BF7]">
                        {/* <a href="#" className="mr-[30px]">Home</a> */}
                        {/* <a href="#" className="mr-[30px]" onClick={handleAboutUsClick}>About us</a> */}
                        {/* <a href="#" onClick={handlePricingClick}>Pricing</a> */}
                    </div>
                    <div className="flex w-auto h-[80%] mr-[10px]">
                        <div onClick={handleCreateAccountClick} className="w-auto h-[100%] p-[5px] pt-[7px] bg-[#005AFF] justify-center items-center text-[#fff] hover: cursor-pointer"><a>Create account</a></div>
                        <div onClick={handleLoginClick} className="ml-[1px] w-auto h-[100%] p-[5px] pt-[7px] bg-[#4B58A6] justify-center items-center text-[#fff] hover: cursor-pointer"><a>Login</a></div>
                    </div>
                </div>
            </>
        </>
    );
}
