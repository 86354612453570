export default function Footer() {

    return (
        <>
            <div className="w-[100%] h-auto bg-[white]">
                <div className="w-[100%] h-[50px] bg-[white]"></div>
                <div className="w-[80%] ml-auto mr-auto flex justify-between">
                    <div className="w-[40%]">
                        <div className="ml-5 bg-no-repeat items-center justify-center bg-center bg-cover h-10 w-[180px] bg-Logo-01"></div>
                    </div>
                    {/* <div>
                        <a className="text-[#005aff] text-[20px] font-bold">Company</a><br />
                        <div className="mt-5">
                            <a  className="text-[#005aff] text-[15px]">Home</a>
                        </div>
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">About us</a>
                        </div>
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">Pricing</a>
                        </div>
                    </div> */}

                    <div>
                        <a className="text-[#005aff] text-[20px] font-bold">Legal</a><br />
                        <div className="mt-5">
                            <a href="https://tollbooth.app/privacypolicy" target="_blank" className="text-[#005aff] text-[15px]">Privacy policy</a>
                        </div>
                        {/* <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">Terms of use</a>
                        </div> */}

                    </div>

                    <div>
                        <a className="text-[#005aff] text-[20px] font-bold">Contact us</a><br />
                        <div className="mt-5">
                            <a className="text-[#005aff] text-[15px]">info@tollbooth.app</a>
                        </div>

                    </div>
                </div>
                <div className="w-[100%] h-[50px] bg-[white] text-center mt-[50px] text-[#699BF7]"><a>Copyright © 2024 First Providence Group, LLC. All rights Reserved</a></div>
            </div>
        </>
    );
}
