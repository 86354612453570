import React, { useCallback, useMemo, useState, useEffect } from "react";
import { THEME } from "../../../constant";
import MaterialReactTable, {
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { GetUserListApi } from "../../../api/admin/getUserList";
import { EditUser } from "../../dialog";

const UserTable = (props: any) => {
  // const dispatch = useDispatch();

  const [tableData, setTableData] = useState([{}]);

  const [allPageSize, setAllPageSize] = useState(Number);

  const columns: any = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
        Cell: (cell: any) => <div>{cell.row.original.name}</div>,
      },
      {
        accessorKey: "email",
        header: `Email`,
        size: 250,
        Cell: (cell: any) => <div>{cell.row.original.email}</div>,
      },
      {
        accessorKey: "withdraw",
        header: "Withdraw",
        size: 250,
        Cell: (cell: any) => <div>{cell.row.original.withdraw}</div>,
      },
      {
        accessorKey: "stripe_status",
        header: "Subscribe Status",
        size: 150,
        Cell: (cell: any) => {
          const style =
            cell.row.original.stripe_status === "active"
              ? { color: "blue" }
              : { color: "red" };
          return <div style={style}>{cell.row.original.stripe_status}</div>;
        },
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 150,
        Cell: (cell: any) => {
          const style =
            cell.row.original.role === "admin"
              ? { color: "red" }
              : { color: "blue" };
          return <div style={style}>{cell.row.original.role}</div>;
        },
      },
    ],
    []
  );

  const handlePaginationChange = (event: any, value: number) => {
    const pageNum = value;
    // getSuperAdmin(pageNum - 1, pagination.pageSize);
    GetUsersList(pageNum - 1, 10)
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const GetUsersList = async (pageIndex: number, pageSize: number) => {
    const reqData = {
      pageNum: pageIndex,
      pageSize: pageSize,
    };
    const res = await GetUserListApi(reqData);

    setAllPageSize(res.totalPages);
    setTableData(res.data);
  };

  useEffect(() => {
    GetUsersList(pagination.pageIndex, pagination.pageSize);
  }, []);

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
            "mrt-row-actions": {
                muiTableHeadCellProps: {
                    align: "center",
                },
                size: 50,
            },
        }}
        columns={columns}
        data={tableData}
        enableEditing
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <EditUser
              data={tableData[row.index]}
              index={row.index}
              // update={updateEdit}
            />
            <div className="w-5" />
            {/* <DeleteSuperAdmin
              data={tableData[row.index]}
              index={row.index}
              update={updateDelete}
              updateNumber={props.updateNumber}
            /> */}
            <div className="w-5" />
            {/* <ResetPassword data={tableData[row.index]} /> */}
          </Box>
        )}

        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.HEADER_COLOR,
            borderBottom: `1px solid ${THEME.TABLE_DEVIDER}`,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            backgroundColor: "white",
            borderBottom: `1px solid ${THEME.TABLE_DEVIDER}`,
            color: THEME.HEADER_COLOR,
          },
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.LAVEL_COLOR,
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.HEADER_COLOR,
          },
        }}
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
        }}
        muiTablePaperProps={{
          color: "white",
        }}
        onPaginationChange={setPagination}
        state={{ pagination }}
        renderBottomToolbar={({ table }) => (
          <div className="h-16 white">
            <div className="float-right">
              <Pagination
                count={allPageSize}
                shape="rounded"
                color="primary"
                sx={{ color: "white" }}
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box>
            <MRT_ToggleGlobalFilterButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
              color="secondary"
            />
            <MRT_ToggleFiltersButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
            <MRT_ToggleDensePaddingButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
            <MRT_FullScreenToggleButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
          </Box>
        )}
        muiTableBodyRowProps={{ defaultValue: 100 }}
      />
      {/* )} */}
    </>
  );
};

export default UserTable;
