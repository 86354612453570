import React, { useCallback, useMemo, useState, useEffect } from "react";
import { THEME } from "../../../constant";
import MaterialReactTable, {
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { GetTransactionListApi } from "../../../api/admin/getTransactionList";
import { UserGetTransactionListApi } from "../../../api/transaction/getList";

const TransactionTable = (props: any) => {
  // const dispatch = useDispatch();

  const [tableData, setTableData] = useState([{}]);

  const [allPageSize, setAllPageSize] = useState(Number);

  const columns: any = useMemo(
    () => [
      {
        accessorKey: "sender",
        header: "Sender",
        size: 150,
        Cell: (cell: any) => <div>{cell.row.original.sender}</div>,
      },
      {
        accessorKey: "receiver",
        header: `Receiver`,
        size: 250,
        Cell: (cell: any) => <div>{cell.row.original.receiver}</div>,
      },
      {
        accessorKey: "folder",
        header: "Folder",
        size: 250,
        Cell: (cell: any) => <div>{cell.row.original.folder}</div>,
      },
      {
        accessorKey: "status",
        header: "Payment staus",
        size: 150,
        Cell: (cell: any) => {
          const style =
            cell.row.original.status === "paid"
              ? { color: "blue" }
              : { color: "red" };
          return <div style={style}>{cell.row.original.status}</div>;
        },
      },
    ],
    []
  );

  const handlePaginationChange = (event: any, value: number) => {
    const pageNum = value;

    // getSuperAdmin(pageNum - 1, pagination.pageSize);
    GetTransactionsList(pageNum - 1, 10);
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const GetTransactionsList = async (pageIndex: number, pageSize: number) => {
    const reqData = {
      pageNum: pageIndex,
      pageSize: pageSize,
    };
    const res = await UserGetTransactionListApi(reqData);

    setAllPageSize(res?.totalPages);
    setTableData(res.data);
  };

  useEffect(() => {
    GetTransactionsList(pagination.pageIndex, pagination.pageSize);
  }, []);

  return (
    <>
      <MaterialReactTable
        // displayColumnDefOptions={{
        //     "mrt-row-actions": {
        //         muiTableHeadCellProps: {
        //             align: "center",
        //         },
        //         size: 50,
        //     },
        // }}
        columns={columns}
        data={tableData}
        // enableEditing
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.HEADER_COLOR,
            borderBottom: `1px solid ${THEME.TABLE_DEVIDER}`,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            backgroundColor: "white",
            borderBottom: `1px solid ${THEME.TABLE_DEVIDER}`,
            color: THEME.HEADER_COLOR,
          },
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.LAVEL_COLOR,
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: "white",
            color: THEME.HEADER_COLOR,
          },
        }}
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
        }}
        muiTablePaperProps={{
          color: "white",
        }}
        onPaginationChange={setPagination}
        state={{ pagination }}
        renderBottomToolbar={({ table }) => (
          <div className="h-16 white">
            <div className="float-right">
              <Pagination
                count={allPageSize}
                shape="rounded"
                color="primary"
                sx={{ color: "white" }}
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        )}
        renderToolbarInternalActions={({ table }) => (
          <Box>
            <MRT_ToggleGlobalFilterButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
              color="secondary"
            />
            <MRT_ToggleFiltersButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
            <MRT_ToggleDensePaddingButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
            <MRT_FullScreenToggleButton
              table={table}
              sx={{ color: THEME.HEADER_COLOR }}
            />
          </Box>
        )}
        muiTableBodyRowProps={{ defaultValue: 100 }}
      />
      {/* )} */}
    </>
  );
};

export default TransactionTable;
