import MainHeader from "../components/main_component/header";
import UserMembershipComponent from "../components/main_component/stripe/MemberShip";
import { useMediaQuery } from "react-responsive";

function Subscribe() {
    const isLaptopOrMobile = useMediaQuery({
        minWidth: 850,
      });
  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <MainHeader />
        <div className={isLaptopOrMobile ? "flex justify-between w-[95%]" : "flex justify-between w-[100%]"}>
          
          <div className={isLaptopOrMobile ? "w-[100%] ml-[5%] h-screen" : "w-[100%] h-screen"} >
            <div className="mt-[100px] h-[calc(screen - 100px)] overflow-auto">
              <UserMembershipComponent />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Subscribe;
