import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SignupForm from '../components/signup_form';
import { useMediaQuery } from "react-responsive";
import SignupMobileForm from '../components/mobile/signup';

function SignUp() {

  let isLaptopOrMobile = useMediaQuery({
    minWidth: 1000,
  });

  return (
    <>
      <div className="w-screen h-screen bg-[#e5e9f6]">
        {isLaptopOrMobile ? (
          <>
            <Grid container>
              <Grid item xs={4}>
                <SignupForm />
              </Grid>
              <Grid item xs={8}>
                <div className='bg-no-repeat w-[100%] h-screen bg-cover bg-signup_background'></div>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <SignupMobileForm />
          </>
        )}
      </div>
    </>
  );
}

export default SignUp;
