const SOFTWARE_NAME: string = "Konx";
const LOGIN_ACCOUNT: string = "Login account";
const YOUR_EMAIL: string = "Your email";
const PASSWORD: string = "Password";
const FORGOT_PASSWORD: string = "Forgot password";
const Submit: string = "Submit";
const LOGIN_NOW: string = "Login now ?";
const DASHBOARD_TITLE: string = "OCPP CHARDER - KONX";
const SUPER_ADMIN_LIST: string = "Super Admin List";
const TOTAL_NUMBER: string = "total number"
const BUSINESS_USER_LIST: string = "Business User List"
const BUSINESS_ACCOUNT_LIST: string = "Business Account List"
const TOTAL_EARNING: string = "Total Earning"
const TOOLS_STATUS: string = "Tools status"
const DASHBOARD: string = "Dashboard"
const SA_MANAGEMENT: string = "SA management"
const BUSINESS_MANAGEMENT: string = "Business management"
const BUSINESS_ACCOUNT: string = "Business Accounts"
const CREATE_BUSINESS_ACCOUNT: string = "Create Business Account"
const ENABLE_DISABLE_BUSINESS_ACCOUNT: string = "Enable/Disable Business Account"
const SASS_PLAN: string = "SaaS - Plan"
const BUSINESS_USERS: string = "Business users"
const BACKEND_URL = process.env.BACKEND_URL || 'https://tollbooth.app'
// const BACKEND_URL = process.env.BACKEND_URL || 'http://localhost:3000'

const HEADER = () => (
  {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('t')}`
    }
  })

const StripePubKey = 'pk_live_51JW2cXCLpovxyVwROTGY3cJyz5ero9aCbXGHD7y64xlZN7Yy390Iu1EH2dAbhp3K2SRKXJ6b3ZR9MfU4zxqEXMrF00hJ4AV7jM'

const PrivacyPolicyContent = `First Providence Group, LLC operates “Tollbooth” as an app and extension to provide monetization services for inbound email and other messages received. 
Tollbooth is committed to protecting users' privacy and addressing potential privacy concerns. The following information has been posted to help you, the consumer, understand what private information we collect and how your information is used.
California residents may reference the below sections of this Privacy Policy for Your California Privacy Rights.`;

const PrivacyPolicyContent1 = `To best serve your needs, we collect the following types of information when you visit (website).`;

const PrivacyPolicyContent2 = `We collect the following personal information from our users:\n
- Name (first name and last name)\n
- Email\n
- Basic Employment Information (company role, department)\n
- Payment / Billing Information (billing address, billing name)\n
- IP Address\n
- Browser / User Agent Information\n
- Geolocation Data (country, city)\n
- Demographic Data (age, sex)\n
You can always choose to provide less information. However, omitting some of this personal information may limit your ability to access all of the benefits of our website and services. For example, we will not be able to process transactions without payment information and a billing address.`;

const THEME: {
  BUTTON_COLOR: string;
  LAVEL_COLOR: string;
  HOME_BOARD_COLOR: string;
  HEADER_COLOR: string;
  WHITE_COLOR: string;
  TABLE_HEADER: string;
  TABLE_DEVIDER: string;
  PRIMARY_COLOR: string;
} = {
  BUTTON_COLOR: "#28c76f",
  LAVEL_COLOR: "#c7cad9",
  HOME_BOARD_COLOR: "#25293c",
  WHITE_COLOR: "#FFF",
  HEADER_COLOR: "#2e3248",
  TABLE_HEADER: "#4a5072",
  TABLE_DEVIDER: "#44475d",
  PRIMARY_COLOR: "#1976d2"
};

export {
  HEADER,
  SOFTWARE_NAME,
  LOGIN_ACCOUNT,
  FORGOT_PASSWORD,
  LOGIN_NOW,
  PASSWORD,
  Submit,
  YOUR_EMAIL,
  THEME,
  DASHBOARD_TITLE,
  SUPER_ADMIN_LIST,
  BUSINESS_USER_LIST,
  BUSINESS_ACCOUNT_LIST,
  TOTAL_NUMBER,
  TOTAL_EARNING,
  TOOLS_STATUS,
  DASHBOARD,
  SA_MANAGEMENT,
  BUSINESS_MANAGEMENT,
  BUSINESS_ACCOUNT,
  CREATE_BUSINESS_ACCOUNT,
  ENABLE_DISABLE_BUSINESS_ACCOUNT,
  SASS_PLAN,
  BUSINESS_USERS,
  BACKEND_URL,
  PrivacyPolicyContent,
  PrivacyPolicyContent1,
  PrivacyPolicyContent2,
  StripePubKey
};
