import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { PAGE_TYPES } from "../actions/types";
import Header from "../components/header";
import DashboardMainViewContent from "../components/dashboard_main_view/view_content";
import DashboardMainViewPicture from "../components/dashboard_main_view/view_picture";
import DashboardAdViewContent from "../components/dashboard_ad_view/view_content";
import DashboardAdViewPicture from "../components/dashboard_ad_view/view_picture";
import DashboardWorksView from "../components/dashboard_works_view/view_content";
import DashboardPricing from "../components/dashboard_pricing/dashboard_pricing";
import FAQ from "../components/faq/faq";
import AboutUs from "../components/about_us"; // Import AboutUs component
import GettingStarted from "../components/getting_started";
import GettingStartedToday from "../components/gettingstarted_today";
import Footer from "../components/footer";
import DashboardWorksViewMobile from "../components/mobile/workview";
import DashboardPricingMobile from "../components/mobile/pricing";
import FAQMobile from "../components/mobile/faq";
import AboutUsMobile from "../components/mobile/aboutus";
import GettingStartedMobile from "../components/mobile/gettingstarted";
import GettingStartedTodayMobile from "../components/mobile/gettingstartedToday";
import FooterMobile from "../components/mobile/footer";
import MobileHeader from "../components/mobile/header";

export default function Homepage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: PAGE_TYPES.CURRENT_PAGE, payload: "dashboard" });
  }, [dispatch]);

  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });

  const aboutUsRef = useRef<HTMLDivElement>(null); // Create a ref for AboutUs component
  const pricingRef = useRef<HTMLDivElement>(null); // Create a ref for AboutUs component

  return (
    <>
      <div className="h-screen overflow-auto bg-[#e5e9f6]">
        <div className="h-[100%] right-0 z-10 relative bg-dashboard_picture_spheer bg-no-repeat bg-cover"></div>
        {isLaptopOrMobile ? (
          <>
            <Header />
          </>
        ) : (
          <>
            <MobileHeader />
          </>
        )}
        <div className="w-[100%] h-auto absolute top-0 mt-[100px]">
          <div
            id="dashboardMainView"
            className="w-[100%] h-auto flex bg-[#e5e9f6]"
          >
            {isLaptopOrMobile ? (
              <>
                <DashboardMainViewContent />
                <DashboardMainViewPicture />
              </>
            ) : (
              <>
                <DashboardMainViewContent />
              </>
            )}
          </div>

          {isLaptopOrMobile ? (
            <>
              <div
                id="dashboardAdView"
                className="bg-[#e5e9f6] w-[100%] h-auto flex justify-around"
              >
                <DashboardAdViewContent />
                {/* <div className="w-[20px] h-100% bg-[#003fb2]"></div> */}
                {/* <DashboardAdViewPicture /> */}
              </div>
              <DashboardWorksView />
              {/* <DashboardPricing ref={pricingRef} /> */}
              {/* <FAQ /> */}
              {/* <AboutUs ref={aboutUsRef} />  */}
              {/* <GettingStarted /> */}
              <GettingStartedToday />
              <Footer />
            </>
          ) : (
            <>
              <div
                id="dashboardAdView"
                className="bg-[#e5e9f6] w-[100%] h-auto"
              >
                <div className="h-[20px] w-100% bg-[#e5e9f6]"></div>
                <DashboardAdViewContent />
                {/* <div className="h-[10px] w-100% bg-[#003fb2]"></div>
                                <DashboardAdViewPicture /> */}
              </div>
              <DashboardWorksViewMobile />
              {/* <DashboardPricingMobile /> */}
              {/* <FAQMobile /> */}
              {/* <AboutUsMobile /> */}
              {/* <GettingStartedMobile /> */}
              <GettingStartedTodayMobile />
              <FooterMobile />
            </>
          )}
        </div>
      </div>
    </>
  );
}
