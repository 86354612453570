import { useLocation } from "react-router-dom";
import MainHeader from "../components/main_component/header";
import { BACKEND_URL } from "../constant";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { SuccessSubscriptionApi } from "../api/stripe/successSubscribe";
import { useNavigate } from "react-router-dom";
import { decodeAES } from "../@core/utils/decodeAES";
import { RegisterApi } from "../api/auth/Register";

function SuccessSubscription() {
  const Navigate = useNavigate();

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const sessionId: string | null = searchParam.get("session_id");
  const senssionEncrypt = localStorage.getItem("s")?.toString();

  const [session, setSession] = useState<String | any>("");
  const [senssion_Encrypt, setSession_Encrypt] = useState<String | any>("");

  const successSubscription = async (
    sessionId: string,
    senssionEncrypt: string | undefined
  ) => {
    try {
      if (!sessionId) {
        toast.error("Please use a valid session");
        localStorage.clear();
        Navigate("/auth/signup");
        return;
      } else if (!senssionEncrypt) {
        toast.error("Use valid session");
        localStorage.clear();
        Navigate("/auth/signup");
        return;
      }

      const decryptSession = decodeAES(senssionEncrypt);

      if (decryptSession != sessionId) {
        toast.error("Your session is not valid.");
        localStorage.clear();
        Navigate("/auth/signup");
        return;
      }

      const nameEncode = localStorage.getItem("n")?.toString();
      const passwordEncode = localStorage.getItem("p")?.toString();
      const emailEncode = localStorage.getItem("e")?.toString();

      if (!nameEncode || !passwordEncode || !emailEncode) {
        toast.error("Use valid credential.");
        return;
      }

      const name = decodeAES(nameEncode)
      const password = decodeAES(passwordEncode)
      const email = decodeAES(emailEncode)
      // api for auth here.
      const req = {
        name,
        email,
        password,
        sessionId,
      };

      const resAuthRegister = await RegisterApi(req);

      localStorage.clear();
      if (resAuthRegister.data?.status == 200) {
        toast.success(resAuthRegister.data?.message);
      } else {
        toast.error(resAuthRegister.data?.message);
        Navigate("/auth/signup");
      }
    } catch (error) {
      toast.error("An error occurred while register.");
    }
  };

  useEffect(() => {
    setSession(sessionId);
    setSession_Encrypt(senssionEncrypt);
  }, []);

  useEffect(() => {
    if (session || senssion_Encrypt) {
      successSubscription(session, senssion_Encrypt);
    }
  }, [session]);

  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <MainHeader />
        <div className="h-screen justify-center items-center flex">
          <div>
            <a className="text-button">
              You subscribed successfully. You can use our service.
            </a>
            <div className="justify-center flex mt-5">
              <a className="text-[#942652]" href={`${BACKEND_URL}/auth/login`}>
                Go to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessSubscription;
