import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const UserGetTransactionListApi = async (data: { pageNum: number; pageSize: number }) => {

  const res = await axios.post('api/transaction/getlist', data, HEADER());
  
  return res.data
};

export { UserGetTransactionListApi };
