import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const TokenValidationApi = async () => {

  try {
    const token = localStorage.getItem('t');

    if (!token) {
      return false
    }
    
    const res = await axios.post('api/auth/tokenvalidation', {}, HEADER());
    return res.data
  } catch (error) {
    return false
  }
};

export { TokenValidationApi };
