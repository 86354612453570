import CryptoJS from 'crypto-js';

const secretKey: string  = process.env.REACT_APP_SECRET || "Default"

export const decodeAES = (encodedData: string) => {

    const bytes = CryptoJS.AES.decrypt(encodedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  }
  

