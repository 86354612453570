import { EventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FORGOT_PASSWORD,
  LOGIN_ACCOUNT,
  PASSWORD,
  SOFTWARE_NAME,
  Submit,
  YOUR_EMAIL,
} from "../constant";
import { toast } from "react-hot-toast";
import { LoginApi } from "../api";
import LoginForm from "../components/login_form";

function Login() {
  
  return (
    <>
      <div className="w-screen h-screen justify-center items-center flex">
        <LoginForm />
      </div>
    </>
  );
}

export default Login;
