import axios from "../../utils/axios";

const RegisterApi = async (data: { name: string, email: string, password: string, sessionId: string }) => {

  const res = await axios.post('api/auth/register', data);
  
  return res
};

export { RegisterApi };
