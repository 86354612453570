import MainHeader from "../components/main_component/header";
import MainNavbar from "../components/main_component/navbar";
import TransactionTable from "../components/main_component/transaction_table";
import WelcomBoard from "../components/main_component/welcome_board";
import { useMediaQuery } from "react-responsive";

function Dashboard() {
  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });
  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <MainHeader />

        {isLaptopOrMobile ? (
          <>
            <div className="flex justify-between w-[95%]">
              <MainNavbar />
              <span className="w-[3%]" />

              <div className="w-[100%] ">
                <div className="mt-[100px] h-auto overflow-auto">
                  <WelcomBoard />
                  <div className="w-[100%] h-[calc(screen-100px)] overflow-auto mt-[30px]">
                    <TransactionTable />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between w-[100%]">
              <MainNavbar />
              <span className="w-[3%]" />

              <div className="w-[100%] ">
                <div className="mt-[100px] h-auto overflow-auto">
                  <WelcomBoard />
                  <div className="w-[100%] mt-[30px]">
                    <TransactionTable />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Dashboard;
