import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const CreateSessionApi = async (email : string) => {

  const res = await axios.post('api/stripe/createStripeSession', {email});
  
  return res.data
};

export { CreateSessionApi };
