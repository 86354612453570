import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from "@mui/material/Button";
import { THEME, DASHBOARD, SA_MANAGEMENT } from "../../constant";
import { useSelector, useDispatch } from "react-redux";

export default function DashboardMainViewPicture() {
    

    return (
        <>
            <div className="w-[100%] h-[auto] relative z-50">
                <div className="bg-no-repeat items-center justify-center bg-center bg-contain h-[500px] bg-dashboard_view_picture"></div>
            </div>
        </>
    );
}
