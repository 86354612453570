import MainHeader from "../components/main_component/header";
import MainNavbar from "../components/main_component/navbar";
import UserMembershipComponent from "../components/main_component/stripe/MemberShip";
import StripeConnectionComponent from "../components/main_component/stripe/StripeConnectionComponent";

function Settings() {

  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <MainHeader />
        <div className="flex justify-between w-[95%]">
          <MainNavbar />
          <span className="w-[3%]" />

          {/* <div className="w-[100%] ">
            <div className="mt-[100px] h-[600px] overflow-auto">
              <StripeConnectionComponent />
            </div>
          </div> */}

          <div className="w-[100%] ">
            <div className="mt-[100px] h-[600px] overflow-auto">
              <UserMembershipComponent />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Settings;
