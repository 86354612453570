import CryptoJS from 'crypto-js';

const secretKey: string  = process.env.REACT_APP_SECRET || "Default"

export const encodeAES = (originalData: string) => {

    const encryptedData = CryptoJS.AES.encrypt(originalData, secretKey).toString();

    return encryptedData;
  }
  

