import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { HEADER } from '../../../constant';

const StripeOauthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const fetchAccessToken = async () => {
      const query = new URLSearchParams(location.search);
      const code = query.get('code');

      if (code) {
        try {
          const response = await axios.get(`/stripe/oauth/callback?code=${code}`, HEADER());
          // Handle success (e.g., show a success message or update UI)
          console.log("Stripe account connected successfully:", response.data);
        } catch (error) {
          console.error("Error during Stripe OAuth callback:", error);
          // Handle error (e.g., show an error message to the user)
        }
      }
    };

    fetchAccessToken();
  }, [location.search]);

  return (
    <div>
      Connecting your Stripe account...
    </div>
  );
};

export default StripeOauthCallback;
