export default function DashboardWorksViewMobile() {
    
    return (
        <>
            <div className="w-[100%] h-auto relative bg-[white]">
                <div className="mt-[30px] w-[100%] text-[black] text-center text-[20px]">
                    <a>How it works</a>
                </div>
                <div className="mt-[30px] ml-auto mr-auto w-[80%] h-[100px] bg-work_line bg-contain bg-no-repeat"></div>
                <div className="ml-auto mr-auto w-[80%] h-auto mb-[80px]"> 
                    <div className="w-[100%] h-auto">
                        <div className="ml-auto mr-auto bg-work_icon1 bg-no-repeat bg-center w-[100px] h-[100px] bg-cover"></div>
                        <div className="text-center">
                            <a>Install extension</a>
                        </div>
                        <div className="text-center mt-[10px]">
                            <a>Download and install the _____ extension</a>
                        </div>
                    </div>

                    <div className="w-[100%] h-auto">
                        <div className="ml-auto mr-auto bg-work_icon1 bg-no-repeat bg-center w-[100px] h-[100px] bg-cover"></div>
                        <div className="text-center">
                            <a>Link email</a>
                        </div>
                        <div className="text-center mt-[10px]">
                            <a>Connect your email account securely</a>
                        </div>
                    </div>

                    <div className="w-[100%] h-auto">
                        <div className="ml-auto mr-auto bg-work_icon1 bg-no-repeat bg-center w-[100px] h-[100px] bg-cover"></div>
                        <div className="text-center">
                            <a>Filter Emails</a>
                        </div>
                        <div className="text-center mt-[10px]">
                            <a>Promotional emails filtered to spam or promotions are automatically identified for monetization.</a>
                        </div>
                    </div>

                    <div className="w-[100%] h-auto">
                        <div className="ml-auto mr-auto bg-work_icon1 bg-no-repeat bg-center w-[100px] h-[100px] bg-cover"></div>
                        <div className="text-center">
                            <a>Get paid</a>
                        </div>
                        <div className="text-center mt-[10px]">
                            <a>Earn money for every highlighted email</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
