import { useMediaQuery } from "react-responsive";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import { GetEarningsApi } from "../../../api/transaction/getEarnings";
import React, { useEffect, useState } from "react";
import { WithdrawModal } from "../../dialog";

export default function WelcomBoard() {
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [name, setName] = useState("");

  const isLaptopOrMobile = useMediaQuery({
    minWidth: 850,
  });

  const getEarnings = async () => {
    const data = await GetEarningsApi();
    setTotalEarnings(data.data.totalEarnings);
    setTotalWithdraw(data.data.totalWithdraw);
    setCurrentBalance(data.data.currentBalance);
    setName(data.data.name);
  };

  const handleWithdrawClick = async () => {};

  useEffect(() => {
    getEarnings();
  }, []);

  return (
    <>
      <div className="w-[100%] h-auto relative ">
        <div className="w-[100%] h-auto bg-white pb-8 rounded-[5px]">
          <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold text-[#121212]">
            <a>👋 Hi {name} !</a>
          </div>

          {isLaptopOrMobile ? (
            <div className="flex justify-between">
              <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
                <a>Your available balance is : </a>
                <a className="font-normal text-[25px]">$ {currentBalance}</a>
              </div>

              <div className="mr-[30px] w-[150px] h-auto rounded-[5px] text-white bg-[#0500FC] hover:cursor-pointer">
                <WithdrawModal />
              </div>
            </div>
          ) : (
            <div>
              <div className="w-[100%] pt-8 pl-10 text-[18px] font-bold">
                <a>Your available balance is : </a>
                <a className="font-normal text-[25px]">$ {currentBalance}</a>
              </div>

              <div className="mr-[30px] w-[90%] ml-[5%] h-auto rounded-[5px] text-white bg-[#0500FC] hover:cursor-pointer">
                <WithdrawModal />
              </div>
            </div>
          )}

          <div className="w-[95%] h-auto flex mt-10">
            <div className="w-[100%] h-auto ml-10 border border-indigo-600 rounded-[5px] p-3">
              <a>Overal earnings</a>
              <div className="font-bold text-[15px]">$ {totalEarnings}</div>
            </div>

            <div className="w-[100%] h-auto ml-10 border border-indigo-600 rounded-[5px] p-3">
              <a>Overal withdrawals</a>
              <div className="font-bold text-[15px]">$ {totalWithdraw}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
