import { useLocation } from "react-router-dom";
import MainHeader from "../components/main_component/header";
import { BACKEND_URL } from "../constant";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { CancelSubscriptionApi } from "../api/stripe/cancelSubscribe";
import { useNavigate } from "react-router-dom";
import { decodeAES } from "../@core/utils/decodeAES";

function CancelSubscription() {
  const Navigate = useNavigate();

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const sessionId: string | null = searchParam.get("session_id");
  const senssionEncrypt = localStorage.getItem("s")?.toString();

  const [session, setSession] = useState<String | any>("");
  const [senssion_Encrypt, setSession_Encrypt] = useState<String | any>("");

  const cancelSubscription = async (
    sessionId: string,
    senssionEncrypt: string | undefined
  ) => {
    try {
      if (!sessionId) {
        toast.error("Please use a valid session");
        return;
      } else if (!senssionEncrypt) {
        toast.error("Use valid session");
        Navigate("/auth/signup");
        return;
      }

      const decryptSession = decodeAES(senssionEncrypt);

      if (decryptSession != sessionId) {
        toast.error("Your session is not valid.");
        return;
      }

      toast.error("You canceled subscribe.");
      localStorage.clear();
      
    } catch (error) {
      toast.error("An error occurred while cancelling the subscription.");
    }
  };

  useEffect(() => {
    setSession(sessionId);
    setSession_Encrypt(senssionEncrypt);
  }, []);

  useEffect(() => {
    if (session || senssion_Encrypt) {
      cancelSubscription(session, senssion_Encrypt);
    }
  }, [session]);

  return (
    <>
      <div className="w-[100%] h-auto absolute bg-[#e5e9f6]">
        <div className="h-screen justify-center items-center flex">
          <div>
            <a>
              You canceled subscription. Please subscribe to create account.
            </a>
            <div className="justify-center flex mt-5">
              <a className="text-[#942652]" href={`${BACKEND_URL}/auth/signup`}>
                SUBSCRIBE NOW
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelSubscription;
