import { HEADER } from "../../constant";
import axios from "../../utils/axios";

const GetEarningsApi = async () => {

  const res = await axios.post('api/transaction/getearnings', {}, HEADER());
  
  return res.data
};

export { GetEarningsApi };
