export default function GettingStartedTodayMobile() {

    return (
        <>
            <div className="w-[100%] h-auto bg-[#e5e9f6]">
                <div className="w-[100%] h-[50px] bg-[#e5e9f6]"></div>
                <div className="text-[black] text-center w-[80%] h-auto ml-auto mr-auto">
                    <div className="text-[25px] font-bold">
                        <a>Download Tollbooth today</a>
                    </div>
                    <div className="mt-[20px] text-[15px]">
                        <a>start earning money for your emails today.</a>
                    </div>
                    <div className="mt-[50px] ">
                        <div>
                            <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px]">
                                <a>Get Started</a>
                                <div className="bg-[white] rounded border-white border-solid border-[1px] h-[30px] w-[30px] ">
                                    <div className="bg-no-repeat bg-contain bg-right_arrow w-[20px] h-[20px] m-auto mt-[5px]"></div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-[30px]">
                            <div className="flex justify-between  w-[200px] h-auto pl-[5px] items-center rounded border-white border-solid border-[1px] bg-[#005aff]">
                                <a href="https://chromewebstore.google.com/detail/tollbooth/ipepgfpplofhkcjbccbhggajjgncjoba" target="_blank" className="text-[white]">Download Extension</a>
                                <div className="bg-[white] rounded-sm h-[30px] w-[30px] ">
                                    <div className="bg-no-repeat bg-contain bg-download_icon w-[30px] h-[30px] m-auto "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-[100%] h-[50px] bg-[#e5e9f6]"></div>
            </div>
        </>
    );
}
